import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Typography from '../Typography/Typography'
import { up } from 'styled-breakpoints'

const Styled = styled.div`
  display: block;
  text-align: center;
  min-width: 33.3333%;
  padding: ${({ theme }) =>
    theme.spacing(45 / 8, 'px') + ' ' + theme.spacing(20 / 8, 'px')};
  border-bottom: 1px solid currentColor;

  &:not(:first-child) {
    margin-left: ${({ theme }) => theme.spacing(20 / 8, 'px')};
  }

  ${up('md')} {
    &:first-child:last-child {
      max-width: none;
    }
    width: auto;
    padding: 0 ${({ theme }) => theme.spacing(40 / 8, 'px')};
    margin-bottom: ${({ theme }) => theme.spacing(45 / 8, 'px')};
    border-bottom: 0;
  }
`

const Label = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(10 / 8, 'px')};
`

const Fact = props => {
  const { className, children, label, border } = props

  return (
    <Styled {...{ className, border }}>
      <Label>
        <Typography variant="factsLabel">{label}</Typography>
      </Label>
      <Typography as="div" variant="factsFact">
        {children}
      </Typography>
    </Styled>
  )
}

Fact.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

Fact.defaultProps = {
  border: true,
}

export default Fact
