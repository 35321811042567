import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Button from '../Button'
import Container from '../Container'

const Styled = styled.div``

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: center;
  padding-top: ${({ theme }) => theme.spacing(5, 'px')};
  grid-column: 1/-1;
`

const ShowMore = props => {
  const { initial, showMore, label, labelStyle, labelVariant,  children, noAction, ...passProps } = props
  const [visible, setVisible] = useState(initial)

  const handleOnClick = () => {
    if (showMore < 0) {
      return setVisible(children.length)
    }

    setVisible(visible + showMore)
  }

  React.useEffect(() => {
    setVisible(initial < 0 ? children.length : initial)
  }, [children.length, initial])

  return (
    <Styled {...{ initial, showMore, ...passProps }}>
      {children.slice(0, visible).map((child, i) => {
        return React.cloneElement(child)
      })}
      {initial !== -1 && !noAction && (
        <StyledContainer>
          <Button
            color={labelStyle}
            variant={labelVariant}
            onClick={handleOnClick}
            disabled={visible >= children.length}
          >
            {label}
          </Button>
        </StyledContainer>
      )}
    </Styled>
  )
}

ShowMore.propTypes = {
  children: PropTypes.node,
  initial: PropTypes.number,
  showMore: PropTypes.number,
  labelStyle: PropTypes.string,
  labelVariant: PropTypes.string,
  label: PropTypes.string,
  noAction: PropTypes.bool,
}

ShowMore.defaultProps = {
  initial: 3,
  showMore: -1,
  label: 'Alle anzeigen',
  labelStyle: 'link',
  noAction: false,
}

export default ShowMore
