import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { up } from 'styled-breakpoints'
import { Box } from '@material-ui/core'
import Typography from '../Typography'
import Image from 'gatsby-image'
import Container from '../Container'
import ButtonGroup from '../ButtonGroup'
import Button from '../Button'
import Markdown from '../Markdown'
import Modal from '../Modal'
import cleanPhoneNumber from 'src/helper/cleanPhoneNumber'

const Styled = styled.div`
  display: grid;
  grid-template-columns: 110px 1fr;
  grid-template-rows: auto 1fr;
  grid-gap: 17px 28px;

  ${up('sm')} {
    grid-template-columns: 1fr;
  }
`

const Avatar = styled.div`
  position: relative;
  width: 100%;

  &:before {
    content: '';
    padding-bottom: ${(750 / 600) * 100}%;
    background: #dedede;
    display: block;
  }
`

const StyledImage = styled(Image)`
  position: absolute !important;
  background: #666;
  height: 100% !important;
  width: 100% !important;
  left: 0;
  top: 0;
`

const Content = styled.div`
  padding-bottom: 55px;
  align-self: flex-end;
  position: relative;

  ${up('sm')} {
    align-self: unset;
  }
`

const StyledButton = styled(Button)`
  position: absolute;
  bottom: 0;
  left: 0;
`

const StyledContainer = styled(Container)`
  display: grid;
  grid-gap: 50px;

  ${up('sm')} {
    grid-template-columns: 250px 1fr;
  }
`

const WrappedTypography = styled(Typography)`
  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }
`

const ScrollWrapper = styled(Box)`
  max-height: 100%;
  overflow: auto;
`

const Employee = props => {
  const {
    avatar,
    title,
    firstName,
    lastName,
    position,
    phone,
    email,
    description,
  } = props

  const [modalVisible, setModalVisible] = useState(false)

  const openModal = () => {
    setModalVisible(true)
  }

  const handleOnRequestClose = () => {
    setModalVisible(false)
  }

  return (
    <Styled>
      <Avatar>
        {avatar && <StyledImage fluid={avatar.local.childImageSharp.fluid} />}
      </Avatar>
      <Content>
        <div>
          <Typography variant="personName">
            {firstName} {lastName}
          </Typography>
          {title && (
            <>
              <br></br>
              <Typography variant="personPos">{title}</Typography>
            </>
          )}
          {position && (
            <>
              <br></br>
              <Typography variant="personPos">{position}</Typography>
            </>
          )}
        </div>
        {description && (
          <StyledButton color="black" onClick={openModal}>
            Mehr
          </StyledButton>
        )}
      </Content>
      <Modal
        {...{
          visible: modalVisible,
          onRequestClose: handleOnRequestClose,
          appEl: '#root',
          color: 'white',
        }}
      >
        <ScrollWrapper>
          <Box mx="auto" py={100 / 8}>
            <StyledContainer>
              <div>
                <Box maxWidth="250px">
                  <Avatar>
                    {avatar && avatar.local && (
                      <StyledImage fluid={avatar.local.childImageSharp.fluid} />
                    )}
                  </Avatar>
                </Box>
                <Box mt={2}>
                  <Typography variant="personName">
                    {firstName} {lastName}
                  </Typography>
                  {position && (
                    <>
                      <br></br>
                      <Typography variant="personPos">{position}</Typography>
                    </>
                  )}
                </Box>
                <Box mt={3}>
                  <ButtonGroup>
                    <Button
                      color="black"
                      href={'tel:' + cleanPhoneNumber(phone)}
                    >
                      Anrufen
                    </Button>
                    <Button color="black" href={'mailto:' + email}>
                      E-Mail
                    </Button>
                  </ButtonGroup>
                </Box>
              </div>
              <WrappedTypography variant="body">
                <Markdown source={description} />
              </WrappedTypography>
            </StyledContainer>
          </Box>
        </ScrollWrapper>
      </Modal>
    </Styled>
  )
}

Employee.propTypes = {
  avatar: PropTypes.object,
  title: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  position: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
  description: PropTypes.string,
}

Employee.defaultProps = {
  //
}

export default Employee

export const query = graphql`
  fragment EmployeeFragment on users {
    title
    firstName
    lastName
    position
    phone
    email
    description
    avatar {
      local {
        childImageSharp {
          fluid(maxWidth: 240, quality: 80, fit: COVER) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  }
`
