import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Accordion as AccessibleAccordion } from 'react-accessible-accordion'

const Styled = styled(AccessibleAccordion)`
  display: block;
`

const Accordion = props => {
  const {
    className,
    children,
    allowMultipleExpanded,
    allowZeroExpanded,
    preExpanded,
  } = props

  return (
    <Styled
      {...{ className, allowMultipleExpanded, allowZeroExpanded, preExpanded }}
    >
      {children}
    </Styled>
  )
}

Accordion.propTypes = {
  className: PropTypes.any,
  title: PropTypes.string,
  children: PropTypes.any,
  allowZeroExpanded: PropTypes.bool,
  allowMultipleExpanded: PropTypes.bool,
  preExpanded: PropTypes.arrayOf(PropTypes.string),
}

export default Accordion
