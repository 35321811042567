import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import {
  AccordionItem as Item,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import Typography from '../Typography/Typography'
import Icon from '../Icon/Icon'

const WrappedItem = ({ boxed, ...passProps }) => {
  return <Item {...passProps} />
}

const Styled = styled(WrappedItem)`
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.palette.secondary.main};
  }

  ${({ boxed, theme }) =>
    boxed &&
    css`
      margin-bottom: ${({ theme }) => theme.spacing(2.5, 'px')};
      border: 1px solid ${({ theme }) => theme.palette.secondary.main};
    `}
`

const Heading = styled(AccordionItemHeading)``

const ExpandIcon = styled(Icon)`
  transition: transform 0.25s ease-in-out;
  stroke-width: 1;
  flex-shrink: 0;
`

const WrappedToggle = ({ big, project, ...passProps }) => {
  return <AccordionItemButton {...passProps} />
}

const Toggle = styled(WrappedToggle)`
  padding: 0 ${({ theme }) => theme.spacing(2.5, 'px')};
  outline: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;

  &[aria-expanded='true'] ${ExpandIcon} {
    transform: rotate(45deg);
  }

  ${({ big }) =>
    big &&
    css`
      padding: ${({ theme }) => theme.spacing(60 / 8, 'px')} 0;
    `}

  ${({ project }) =>
    project &&
    css`
      padding-left: 0;
      padding-right: 0;
    `}
`

const WrappedPanel = ({ big, project, ...passProps }) => {
  return <AccordionItemPanel {...passProps} />
}

const Panel = styled(WrappedPanel)`
  @keyframes fadein {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
  padding: ${({ theme }) =>
    `${theme.spacing(0, 'px')} ${theme.spacing(2.5, 'px')} ${theme.spacing(
      1.875,
      'px'
    )} ${theme.spacing(2.5, 'px')}`};

  animation: fadein 0.25s ease-in;

  ${({ big }) =>
    big &&
    css`
      padding: 0 0 ${({ theme }) => theme.spacing(60 / 8, 'px')} 0;
    `}

  ${({ project }) =>
    project &&
    css`
      padding-left: 0;
      padding-right: 0;
    `}
`

const Title = styled.div`
  min-width: 0;
  position: relative;
`

const AccordionItem = props => {
  const { className, title, children, uuid, boxed, big, project } = props

  return (
    <Styled {...{ className, uuid, boxed }}>
      <Heading>
        <Toggle {...{ big, project }}>
          <Title>
            <Typography variant="accordionHeader" as="div">
              {title}
            </Typography>
          </Title>
          <ExpandIcon icon="plus" size={big ? 24 : 30} />
        </Toggle>
      </Heading>
      <Panel {...{ big, project }}>{children}</Panel>
    </Styled>
  )
}

AccordionItem.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  uuid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  boxed: PropTypes.bool,
  big: PropTypes.bool,
  project: PropTypes.bool,
}

AccordionItem.defaultProps = {
  big: false,
  project: false,
}

export default AccordionItem
