import React from 'react'
import Layout from 'src/layout'
import Hero from 'src/components/Hero'
import Section from 'src/components/Section'
import GradientOverlay from 'src/components/GradientOverlay'
import FlexibleLogo from 'src/components/FlexibleLogo'
import HeroContent from 'src/components/HeroContent'
import Typography from 'src/components/Typography'
import BackgroundImage from 'src/components/BackgroundImage'
import Container from 'src/components/Container'
import { Box } from '@material-ui/core'
import Facts from 'src/components/Facts'
import Fact from 'src/components/Fact'
import orderBy from 'lodash/orderBy'
import Grid from 'src/components/Grid'
import Accordion from 'src/components/Accordion'
import AccordionItem from 'src/components/AccordionItem'
import Image from 'src/components/Image'
import Button from 'src/components/Button'
import Competence from 'src/components/Competence'
import Employee from 'src/components/Employee'
import { graphql } from 'gatsby'
import ShowMore from 'src/components/ShowMore'

const Profile = props => {
  const {
    data: {
      imagesStandort: { nodes: imagesStandort },
      imageBanner: { nodes: imageBanner },
      users: { nodes: users },
    },
  } = props

  const important = [
    {
      title: 'Ganzheitliche integrale Planung',
      text:
        'Das bedeutet für uns, Spezialisten aus Planung und Bauen arbeiten über alle Leistungsphasen nach HOAI hinweg Hand in Hand. Erkenntnisse fließen so direkt aus der Bauerfahrung in die Planung ein.',
    },
    {
      title: 'Etablierte Standards',
      text:
        'Ständig entwickeln wir unser internes Qualitätsmanagement weiter, um Zusammenarbeit und Schnittstellen im Team aus eigenen und externen Fachplanern zu optimieren.',
    },
    {
      title: 'Digitales Arbeiten',
      text:
        'Effizient und ganzheitlich Planen schließt für uns BIM ein. Wir entwickeln neue Lösungen und Prozesse. Eigene und fremde Modelle koordinieren wir und tragen sie in Workshops ins ganze Team.',
    },
    {
      title: 'Gelebtes Wissensmanagement',
      text:
        'Für die Qualität unserer Arbeit ist die täglich gelebte Weitergabe von Wissen und die stetige Erweiterung unserer Qualifikation durch interne wie externe Fortbildung ein wesentlicher Baustein.',
    },
  ]

  const metaProps = {
    metaTitle: 'Hahn Helten Architektur – Profil',
    metaDescription:
      'Mit unserer Erfahrung realisieren wir ganzheitliche Architektur – nutzerzentrierte Gebäudestrukturen nach aktuellen Konzepten innerhalb des Qualitäts-, Kosten- und Zeitrahmens. ',
  }

  return (
    <Layout meta={metaProps}>
      {/*
            Hero
      */}
      <Section border={false} flush>
        <Hero>
          <BackgroundImage
            darken={0.2}
            fluid={imageBanner[0].childImageSharp.fluid}
          />
          <GradientOverlay color="projects" />
          <FlexibleLogo color="primary" size={80} />
          <HeroContent>
            <Typography variant="heroLabel" paragraph>
              Profil
            </Typography>
            <Typography variant="hero" paragraph>
              Raum für Entfaltung mit Empathie und Prozessen
            </Typography>
          </HeroContent>
        </Hero>
      </Section>
      {/*
            Intro
      */}
      <Section color="primary" border={false} flush>
        <Container>
          <Box maxWidth={700} mx="auto" mb={60 / 8}>
            <Typography variant="teaser">
              <p>
                Die Bedürfnisse und Erwartungen von Menschen sind das Fundament
                unserer Arbeit.
              </p>
              <p>
                Mit unserer Erfahrung realisieren wir ganzheitliche Architektur
                – nutzerzentrierte Gebäudestrukturen nach aktuellen Konzepten
                innerhalb des Qualitäts-, Kosten- und Zeitrahmens. Dabei bewegen
                wir uns immer in einem Spannungsfeld aus gesellschaftlichen,
                nachhaltigen, gestalterischen und wirtschaftlichen Faktoren.
              </p>
            </Typography>
          </Box>
          <Box mx="auto">
            <Facts border={false}>
              <Fact border={false} label={'Gegründet'}>
                {'1993'}
              </Fact>
              <Fact border={false} label={'Mitarbeiter'}>
                {'60'}
              </Fact>
              <Fact border={false} label={'Laufende Projekte'}>
                {'28'}
              </Fact>
            </Facts>
          </Box>
        </Container>
      </Section>
      {/*
            Tabs
      */}
      <Section color="secondary">
        <Container>
          <Grid variant="2x">
            <Box>
              <Typography variant="h1">Geschäftsfelder</Typography>
              <Typography variant="body" paragraph>
                <p>
                  Hahn Helten Architektur gliedert sich in drei eigenständige
                  Geschäftsfelder. Gemeinsam begleiten und steuern wir Projekte
                  von der ersten Idee bis zum fertigen Gebäude. Durch unser
                  gelebtes Vier-Augen-Prinzip profitieren wir von der
                  Fachkompetenz unserer Bereiche.
                </p>
                <p>
                  Im Netzwerk mit 400 Kollegen an 13 Standorten des
                  Architekturbüros Wenzel + Wenzel entwickeln wir zusammen unser
                  Know-how weiter und erweitern unsere Möglichkeit zur
                  überregionalen Planung und Realisierung unserer Architektur.
                </p>
              </Typography>
            </Box>
            <Box>
              <Accordion allowZeroExpanded>
                <AccordionItem boxed title="HHA Planung GmbH">
                  <Typography variant="body">
                    <p>
                      Seit 1993 planen wir mit derzeit 35 Mitarbeitern
                      überregional mit Schwerpunkt NRW. Wir entwerfen
                      ganzheitliche, nachhaltige Architektur, die baulich zu
                      ihrem Standort passt und auf die Bedürfnisse der Nutzer
                      abgestimmt ist. Unser empathischer Entwurfsansatz spiegelt
                      sich in einer klaren Formensprache der entstehenden
                      Gebäude wider. <br></br> Schon von Beginn der
                      Konzeptfindung an beziehen wir die beteiligten Fachplaner
                      in den Planungsprozess ein. Dieser integraler
                      Planungsansatz ist durch die jahrelange Praxis zu einem
                      dynamischen und effizienten Teil unserer Prozesse geworden
                      und hat uns vor allem in energiesparendem und nachhaltigem
                      Bauen viele Erfolge eingebracht – von Architekturpreisen
                      bis zu Veröffentlichungen in Fachzeitschriften.
                    </p>
                  </Typography>
                </AccordionItem>
                <AccordionItem boxed title="HHA Bauleitung GmbH">
                  <Typography variant="body">
                    <p>
                      Wir bearbeiten mit einem Team von 25 Mitarbeitern
                      Bauprojekte in allen Größen. Unsere eigenen geplanten
                      Projekte setzen wir ebenso um wie die Planungen externer
                      Architekten. <br></br> Neben fachlicher Kompetenz und
                      Flexibilität legen wir Wert auf einen persönlichen Umgang
                      mit unseren Kunden. Wir realisieren Gebäude umsichtig,
                      kosten- und termintreu. Dabei sind uns Funktion, Ästhetik
                      und Wirtschaftlichkeit gleichermaßen wichtig. Eine
                      zeitgemäße technische Ausstattung und die entsprechende
                      Software für alle Tätigkeiten unterstützen den Werkerfolg.{' '}
                      <br></br> Unsere Schwerpunkte bei der Bauleitung liegen in
                      der Kalkulation und Kostenermittlung, in der Ausschreibung
                      und der Objektüberwachung.
                    </p>
                  </Typography>
                </AccordionItem>
                <AccordionItem boxed title="HHA Generalplanung GmbH">
                  <Typography variant="body">
                    <p>
                      Als Generalplaner planen wir seit über 20 Jahren für
                      unsere Auftraggeber ganzheitliche Architektur in hoher
                      Qualität von der ersten Idee bis zum fertigen Gebäude.
                      Dabei sind wir der zentrale Vertrags- und Ansprechpartner
                      für unseren Bauherren und steuern für ihn einen effektiven
                      Projektablauf unter Beachtung seines gesteckten
                      Qualitäts-, Kosten- und Zeitrahmens. <br></br> Durch
                      unseren integralen Planungsansatz entsteht im Sinne der
                      jeweiligen Planungsaufgabe ein enger interdisziplinärer
                      Austausch in einem Netzwerk mit qualifizierten
                      Fachplanern.
                    </p>
                  </Typography>
                </AccordionItem>
              </Accordion>
            </Box>
          </Grid>
        </Container>
      </Section>
      {/*
            Prozesse
      */}
      <Section color="transparent">
        <Container>
          <Typography variant="h1" paragraph>
            Was uns wichtig ist
          </Typography>
          <Grid variant="2x">
            {important.map((entry, key) => (
              <Competence
                key={key}
                index={key + 1}
                title={entry.title}
                text={entry.text}
              />
            ))}
          </Grid>
        </Container>
      </Section>
      {/*
            Team
      */}
      <Section color="secondary">
        <Container>
          <Typography variant="h1" paragraph>
            Unser Team
          </Typography>
          <ShowMore as={Grid} variant="4x" initial={12} labelStyle="black">
            {orderBy(users, 'lastName').map((entry, key) => (
              <Employee {...entry} key={key} />
            ))}
          </ShowMore>
        </Container>
      </Section>
      {/*
            Büro und Standort
      */}
      <Section color="secondary">
        <Container>
          <Box mb={40 / 8}>
            <Grid variant="2x">
              <Box>
                <Image fluid={imagesStandort[0].childImageSharp.fluid} />
              </Box>
              <Box>
                <Image fluid={imagesStandort[1].childImageSharp.fluid} />
              </Box>
            </Grid>
          </Box>
        </Container>
        <Container>
          <Box maxWidth={720}>
            <Typography variant="h1">Büro und Standort</Typography>
            <Typography variant="body" paragraph>
              <p>
                Unser Bürogebäude befindet sich am Rande der Solarsiedlung
                Schurzelter Winkel in Aachen-Laurensberg. 2003 als
                Bürogemeinschaft realisiert, ist unser BOB.1 das erste Modell
                einer Bürogebäude-Serie (Balanced Office Building). Es ist auf
                Energieeffizienz und Nachhaltigkeit optimiert, denn es setzt
                ausschließlich auf oberflächennahe Beheizung und Kühlung per
                Geothermie. Unser Team aus ca. 60 Mitarbeitern arbeitet hier
                über zwei Etagen in einer offenen, flexiblen Bürostruktur
                zusammen. Dazu zählt ebenso die Dachterrasse, von der aus wir
                bis zum Aachener Lousberg schauen können.
              </p>
            </Typography>
            <Button
              href="https://goo.gl/maps/u6xCJA9wVERcG8cdA"
              color="secondary"
              target="_blank"
            >
              Wegbeschreibung anzeigen
            </Button>
          </Box>
        </Container>
      </Section>
      {/*
            Historie
      */}
      <Section color="secondary">
        <Container>
          <Typography variant="h1" paragraph>
            Historie
          </Typography>
        </Container>
        <Container>
          <Box maxWidth={720}>
            <Typography variant="body" paragraph>
              <p>
                Unser Büro wurde 1993 von Prof. Ulrich Hahn und Günter Helten
                als Bürogemeinschaft gegründet. Ab 2004 wurde das bis dahin
                allein bestehende Geschäftsfeld Planung, mit der sich bereits
                seit Ende der 1990er-Jahre etablierenden Generalplanung, um eine
                eigene Bauleitung unter Leitung von Dieter Meyknecht ergänzt. Ab
                2006 trat dann Udo Thiemann als Geschäftsführer in die
                Bauleitung ein.
              </p>
              <p>
                Nach 25 Jahren mit vielen realisierten Projekten im In- und
                Ausland und einem stetig wachsenden Team mit mittlerweile rund
                60 Kollegen und Kolleginnen entschlossen sich unsere beiden
                Gründer zum Jahresende 2018 aus dem Büro auszuscheiden. Seit
                2019 leiten die beiden langjährigen assoziierten Partner Harald
                Schäfer und Christian Fensterer die Geschäftsfelder Planung und
                Generalplanung von Hahn Helten Architektur.
              </p>
            </Typography>
          </Box>
        </Container>
      </Section>
    </Layout>
  )
}

export default Profile

export const query = graphql`
  query {
    users: allUsers(filter: { profile: { eq: true } }) {
      nodes {
        id__normalized
        ...EmployeeFragment
      }
    }

    imageBanner: allFile(
      filter: { relativePath: { eq: "profil_banner_01.jpg" } }
    ) {
      nodes {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }

    imagesStandort: allFile(
      filter: {
        relativePath: {
          in: [
            "profil_buero-und-standort_01.jpg"
            "profil_buero-und-standort_02.jpg"
          ]
        }
      }
      sort: { fields: relativePath }
    ) {
      nodes {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1080) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  }
`
