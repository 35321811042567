import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { up } from 'styled-breakpoints'

const Styled = styled.div`
  color: ${({ theme }) => theme.palette.primary.main};
  overflow: auto;
  display: block;

  &:after,
  &:before {
    content: '';
    width: 40px;
    display: block;
    flex: none;

    ${up('md')} {
      display: none;
    }
  }

  ${up('md')} {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
    display: flex;
    align-items: stretch;
    padding: 0;
    justify-content: center;
    margin-bottom: ${({ theme }) => theme.spacing(45 / 8, 'px')};

    border-bottom: ${({ border }) =>
      border ? '1px solid currentColor' : 'none'};
    > *:not(:last-child) {
      border-right: 1px solid currentColor;
    }
  }
`

const Facts = props => {
  const { className, children, border } = props

  return <Styled {...{ className, border }}>{children}</Styled>
}

Facts.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  border: PropTypes.bool,
}

Facts.defaultProps = {
  border: true,
}

export default Facts
