import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { up } from 'styled-breakpoints'
import padStart from 'lodash/padStart'

const Styled = styled.div`
  font-family: ${({ theme }) => theme.typo.primary};
  font-weight: ${({ theme }) => theme.typo.weight.regular};
  padding: 20px 25px;
  border: 1px solid;

  ${up('sm')} {
    padding: 40px 45px;
  }
`

const Index = styled.div`
  margin-bottom: 12px;
  line-height: 1.25;
  font-size: 64px;
`

const Title = styled.div`
  margin-bottom: 12px;
  line-height: 1.33;
  font-size: 24px;
`

const Text = styled.div`
  margin-bottom: 12px;
  line-height: 1.44;
  font-size: 18px;
`

const Competence = props => {
  const { index, title, text } = props

  return (
    <Styled>
      <Index>{padStart(index, 2, 0)}</Index>
      <Title>{title}</Title>
      <Text>{text}</Text>
    </Styled>
  )
}

Competence.propTypes = {
  index: PropTypes.number,
}

Competence.defaultProps = {
  //
}

export default Competence
